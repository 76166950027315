.logo-header {
  font-weight: 900;
  font-style: italic;
}
.profile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.block {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* ion-modal {
  --height: auto;
} */
.no-wrap {
  flex-wrap: nowrap;
}
.horisontal-block {
  display: flex;
  justify-content: space-between;
}
.big-text {
  text-transform: capitalize;
}
.bottom-gap {
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
}
.game-card {
  height: 15rem;
  display: flex;
  justify-content: center;
}
.game-text {
  font-size: 2.3rem !important;
}

ion-content {
  --background: linear-gradient(45deg, #fff1eb, #ace0f9);;
}

ion-tab-bar {
  --background: #ffffff4a;
}