.flashcard-container {
    perspective: 1000px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .flashcard {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
  }
  
  .card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  
  .hidden {
    opacity: 0;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }


  ion-toggle {
    padding: 12px;
  
    --track-background: #ddd;
    --track-background-checked: #ddd;
  
    --handle-background: #eb7769;
    --handle-background-checked: #95c34e;
  
    --handle-width: 25px;
    --handle-height: 27px;
    --handle-max-height: auto;
    --handle-spacing: 6px;
  
    --handle-border-radius: 4px;
    --handle-box-shadow: none;
  }
  
  ion-toggle::part(track) {
    height: 10px;
    width: 65px;
  
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }

  .language-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .language-toggle ion-label {
    margin: 0 10px;
  }